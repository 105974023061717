<template>
  <router-view></router-view>
</template>

<script>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router';
  import {SET_LOGOUT} from "@/store/auth/mutations";
  import {ROUTE_NAMES_AUTH} from '@/router/modules/auth'

  export default {
    name: 'Logout',
    setup(props, {root}) {
      const store = useStore();
      const router = useRouter();

      store.commit(SET_LOGOUT);
      router.push({name: ROUTE_NAMES_AUTH.LOGIN}).catch(() => {
      });
    }
  }
</script>

<style scoped lang="scss">
</style>
